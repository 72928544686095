


















































import { Vue, Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  methods: { appendSitePrefix },
  components: {},
})
export default class Details extends Vue {
  public appendSitePrefix = appendSitePrefix

  public loading = true
  public loaded = false
  public fetchCartDisabled = true
  public openCatalogueDisabled = false

  public url = null

  public created(): void {
    this.fetchUrl(this.$route.params.orderId)
  }

  public fetchUrl(orderId) {
    this.loading = true
    this.loaded = false
    this.$axios
      .get('/v4/site/order/meca-pro/basket-url/' + orderId)
      .then((response) => {
        if (response.data.newOrderId) {
          this.$router.push({
            name: 'Order/MecaPro',
            params: {
              orderId: response.data.newOrderId,
            },
          })
        } else {
          this.url = response.data.url
          this.loaded = true
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          vxm.alert.error({
            content: err.response.data.error.message,
            title: this.$t('c:common:Error') as string,
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  public openCatalogue(): void {
    this.fetchCartDisabled = false
    this.openCatalogueDisabled = true
    window.open(this.url, '_blank')
  }

  public fetchCar(): void {
    this.fetchCartDisabled = true
    this.openCatalogueDisabled = true
    const orderUrl = appendSitePrefix('/' + this.$route.params.orderId + '/order2/meca_pro/fetch_basket')
    this.$router.push(orderUrl)
  }

  public beforeRouteUpdate(to, from, next): void {
    if (to.params.orderId !== from.params.orderId) {
      this.fetchUrl(to.params.orderId)
    }
    next()
  }
}
